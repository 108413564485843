/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/chart.js@2.9.3/dist/Chart.min.js
 * - /npm/chart.js@2.9.3/dist/Chart.bundle.min.js
 * - /npm/chart.js@2.9.3/dist/Chart.bundle.min.js
 * - /npm/chart.js@2.9.3/dist/Chart.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
